import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import StyledLink from '../StyledLink'

const query = graphql`
  {
    file(relativePath: {
      eq: "404.png"}) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  width: 300px;
  margin: 40px auto 0;
  justify-content: center;
  align-items: center;
`

const isRight = ({ right }) => right
  ? css`
    position: relative;
    left: -6px;
  `
  : css``

export const Span = styled.span`
  font-size: 137px;
  ${isRight}
`
export const ImgContainer = styled.div`
  position: relative;
  top: 6px;
`

export const SubTtitle = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.l};
  margin-top: 20px;
  text-align: center;
`

export const Link = styled(StyledLink)`
  margin: 15px auto 0;
  text-align: center;
  display: block;
  cursor: pointer;
  color: ${({ theme: { color } }) => color.secondary.main};
  text-decoration: underline;
`

const Page404 = () => {
  const data = useStaticQuery(query)

  return (
    <>
      <TitleContainer>
        <Span>4</Span>
        <ImgContainer>
          <Img fixed={data.file.childImageSharp.fixed} />
        </ImgContainer>
        <Span right>4</Span>
      </TitleContainer>
      <SubTtitle>
        La page que vous cherchez n&apos;existe pas
      </SubTtitle>
      <Link to="/">
        Retouner à l&apos;accueil
      </Link>
    </>
  )
}

export default Page404
