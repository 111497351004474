import React from 'react'
import Layout from '../Layout'
// import SEO from '../SEO'

import Page404 from '../components/Page404'

const NotFoundPage = () => (
  <Layout>
    <Page404 />
  </Layout>
)

export default NotFoundPage
